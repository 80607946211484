export const products = [
    {
        img: '2.1-min.jpg',
        imgMin: '2.1-min.jpg',
        title: 'Морозильник Jambo 300W2 300 л серый',
        description: {
            color: 'Серый',
            volume: '300 л',
            type: 'морозильник-ларь',
            camera: 'No Frost',
            power: '14.0 кг/сут',
            class: 'N, ,SN, ,T, ,ST',
            classElectricity: 'А+',
        }
    },
    // {
    //     img: '1.1-min.jpg',
    //     imgMin: '1.1-min.jpg',
    //     title: 'Холодильник Jambo 206K3A серый',
    //     description: {
    //         color: 'Серый',
    //         volume: '30 л/ч',
    //         type: 'морозильник-ларь',
    //         camera: 'No Frost',
    //         power: '14.0 кг/сут',
    //         class: 'N, ,SN, ,T, ,ST',
    //         classElectricity: 'А+',
    //     }
    // },
    {
        img: '1.1-min.jpg',
        imgMin: '1.1-min.jpg',
        title: 'Морозильник Jambo 150GPC 150 л синий',
         description: {
            color: 'Синий',
            volume: '150 л',
            type: 'морозильник-ларь',
            camera: 'Ручное',
            power: '12.0 кг/сут',
            class: 'N, ,SN, ,T, ,ST',
            classElectricity: 'А+',
        }
    },
    // done
    {
        img: '4.1-min.jpg',
        imgMin: '4.1-min.jpg',
        title: 'Морозильник Jambo 203GPF 203 л серый',
         description: {
            color: 'Синий',
            volume: '150 л',
            type: 'морозильник-ларь',
            camera: 'Ручное',
            power: '12.0 кг/сут',
            class: 'N, ,SN, ,T, ,ST',
            classElectricity: 'А+',
        }
    },
    // {
    //     img: '3.1-min.jpg',
    //     imgMin: '3.1-min.jpg',
    //     title: 'Холодильник Jambo BCD-108AT золотистый',
    //      description: {
    //         color: 'Серый',
    //         volume: '30 л/ч',
    //         type: 'морозильник-ларь',
    //         camera: 'No Frost',
    //         power: '14.0 кг/сут',
    //         class: 'N, ,SN, ,T, ,ST',
    //         classElectricity: 'А+',
    //     }
    // },
    // done
        {
        img: '3.1-min.jpg',
        imgMin: '3.1-min.jpg',
        title: 'Морозильник Jambo 101GPF 101 л серый',
         description: {
            color: 'Серый',
            volume: '101 л',
            type: 'морозильник-ларь',
            camera: 'No Frost',
            power: '12.0 кг/сут',
            class: 'N, ,SN, ,T, ,ST',
            classElectricity: 'А+',
        }
    },
    {
        img: '6.1.jpg',
        imgMin: '6.1.jpg',
        title: 'Холодильник Jambo 442WK8CT/L синий',
         description: {
            type: 'Холодильник с морозильником',
            color: 'Синий',
            // volume: '442 л',
            cameraCount: '3',
            // power: '14.0 кг/сут',
            // class: 'N, ,SN, ,T, ,ST',
            classElectricity: 'А+',
        }
    },
    // done
]