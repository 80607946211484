<template>
  <section
    class="bg-gray-800 pt-20 px-6 md:px-10 lg:px-36 space-y-4 md:space-y-20"
    id="product"
  >
<!--    <div class="w-1/3 border-b-2 border-gray-700 pb-4" data-sal="slide-up" data-sal-delay="300">-->
<!--      <p class="text-4xl font-black text-white">-->
<!--        {{ $t("productSection.bigTitle") }}-->
<!--      </p>-->
<!--    </div>-->
    <div class="flex items-center flex-col md:flex-row">
      <div class="md:flex relative justify-center py-3">
        <router-link v-for="(product, idx) in products.slice(1, 4)" :key="idx" :to="'/product/' + (idx + 1)">
          <div class="flex items-center flex-col">
            <img
                    :src="require(`./../../assets/img/products/${product.imgMin}`)"
                    class="w-3/4 xl:w-3/4 rounded-lg shadow-lg image-container"
                    alt=""
                    data-sal="slide-right" data-sal-delay="300"
            />
            <div class="flex-1 items-center" data-sal="slide-up" data-sal-delay="300">
              <p class="text-xl text-white px-2">
                {{ product.title }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="flex justify-center">
      <router-link to="/product" class="phil-white-button-2">
        Все товары
      </router-link>
    </div>
  </section>
</template>

<script>
import {products} from "../../data/products";
import sal from "sal.js";
export default {
  name: "ProductSection",
  data() {
    return {
      products,
    }
  },
  mounted() {
    sal();
  },
};
</script>
<style>
.image-container {
  height: 300px;
}
</style>
