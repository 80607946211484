<template>
  <section
      class="bg-gray-800 px-6 md:px-10 lg:px-36 space-y-4 md:space-y-20" id="y_map">
    <yandex-map
        ref="map"
        :coords="[43.264440, 76.943327]"
        zoom="15"
        class="my-10"
        style="width:100%; height: 400px;"
        :controls="[]"
    >
      <ymap-marker
          marker-id="123"
          marker-type="placemark"
          :coords="coord"
          :balloon="{ body: text }"
      ></ymap-marker>
    </yandex-map>
  </section>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {
  name: "YandexMaps",
  components: {
    yandexMap, ymapMarker
  },
  data() {
    return {
      coord: [43.264440, 76.943327],
      text: 'Мы здесь! По ул. Панфилова 75' ,
    };
  },
  methods: {
    onClick(m) {
      this.$refs.map.myMap.balloon.open(m.coord, m.text);
    },
  },
};
</script>

<style>
</style>
