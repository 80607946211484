<template>
 <hero-section />
  <!-- <carousel-section /> -->
  <!-- <about-us /> -->
  <!-- <choose-us-section /> -->
  <product-section />
  <get-touch-section />
  <yandex-maps />
</template>

<script>
import HeroSection from "./../../components/landing_pages/HeroSection.vue";
// import ChooseUsSection from "./../../components/landing_pages/ChooseUsSection.vue";
import ProductSection from "./../../components/landing_pages/ProductSection.vue";
import GetTouchSection from "./../../components/landing_pages/GetTouchSection.vue";
// import CarouselSection from "../../components/landing_pages/Carousel";
// import AboutUs from "../../components/landing_pages/AboutUs";
import YandexMaps from "./YandexMaps";

export default {
  name: "Home",
  components: {
    YandexMaps,
    // AboutUs,
    // CarouselSection,
    // ChooseUsSection,
    HeroSection,
    ProductSection,
    GetTouchSection,
  },
};
</script>

<style lang="postcss">
@import "./../../../node_modules/sal.js/dist/sal.css";

.phil-white-button {
  @apply px-6 py-3 bg-gray-500 text-white rounded-full font-bold hover:bg-gray-100 hover:shadow-md
}
.phil-white-button-2 {
  @apply px-6 py-3 bg-yellow-500 text-white rounded-full font-bold hover:bg-yellow-100 hover:shadow-md
}
</style>
